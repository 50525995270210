<template>
    <div>
        <div class="sm:mx-8">
            <div class="flex">
                <h1 class="report-title">Who are you
                <span class="text-blue-dark">comunicating</span>
                    <span class="report-title text-blue-dark">
                        with the most<span class="report-title text-gray-800">?</span>
                    </span>
                </h1>
            </div>
            <p class="text-gray-800 max-w-xl sm:text-sm">
                The people you interact with through email may change due to lockdown,<br v-if="!isMobile" />
                for example with friends and colleagues you would normally speak to in person.
                <b>Here’s the contacts you’ve interacted with the most.</b>
            </p>
        </div>
        <div class="md:flex mt-4 md:mt-20 ml-1 sm:mx-2 sm:mt-4 mb-20">
            <div class="md:w-1/2 max-w-3xl flex-2 bg-white shadow-sm rounded">
                <div class="items-center px-6 py-5">
                    <p class="m-0 font-inter font-bold text-lg text-gray-light inline-block mr-2">Top Interaction</p>
                    <Tooltip type="topInteraction" />
                </div>
                <div class="px-4 border border-solid border-b-0 border-r-0 border-l-0 border-gray-300">
                    <div class="px-2 my-4 flex flex-row">
                        <div class="mx-3 my-2 w-2 h-2 bg-indigo-primary rounded"></div>
                        <div class="flex flex-col">
                            <span class="font-bold mb-1">Lockdown</span>
                            <span v-if="!isMobile" class="text-xs text-gray-600"
                                >People you<span v-if="stillConfined">’ve</span> interacted with the most during
                                lockdown</span
                            >
                        </div>
                    </div>
                    <div
                        v-for="({ email, name }, index) of metrics.forthPage.listConfined"
                        :key="email"
                        class="flex p-4 pl-2 border border-solid border-b-0 border-r-0 border-l-0 border-gray-300"
                    >
                        <div class="flex-1 flex whitespace-no-wrap overflow-hidden">
                            <div class="w-4 mr-3">
                                <span>{{ index + 1 }}.</span>
                            </div>
                            <div v-if="name">
                                <span>{{ name }}</span
                                ><span class="mx-2">-</span>
                            </div>
                            <span class="truncate" :class="{ 'text-gray-600': Boolean(name) }">{{ email }}</span>
                        </div>
                    </div>
                </div>
                <div class="px-4 border border-solid border-b-0 border-r-0 border-l-0 border-gray-300">
                    <div class="px-2 my-4 flex flex-row">
                        <div class="mx-3 my-2 w-2 h-2 bg-green-500 rounded"></div>
                        <div class="flex flex-col">
                            <span class="font-bold mb-1">Outside Lockdown</span>
                            <span v-if="!isMobile" class="text-xs text-gray-600"
                                >People you<span v-if="stillConfined">’ve</span> interacted with the most before
                                lockdown began</span
                            >
                        </div>
                    </div>
                    <div
                        v-for="({ email, name }, index) of metrics.forthPage.listNotConfined"
                        :key="email"
                        class="flex p-4 pl-2 border border-solid border-b-0 border-r-0 border-l-0 border-gray-300"
                        :class="{ 'pb-6': index + 1 == 5 }"
                    >
                        <div class="flex-1 flex whitespace-no-wrap overflow-hidden py-1">
                            <div class="w-4 mr-3">
                                <span>{{ index + 1 }}.</span>
                            </div>
                            <div v-if="name">
                                <span>{{ name }}</span
                                ><span class="mx-2">-</span>
                            </div>
                            <span :class="{ 'text-gray-600': Boolean(name), 'truncate': isMobile }">{{ email }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:w-1/3 mx-6 md:mx-12 text-blue-dark sm:mt-6">
                <div>
                    <p class="font-bold m-2">Insights</p>
                    <hr class="insight-hr" />
                    <div>
                        <div class="flex">
                            <div class="my-4">
                                <p class="mx-2 my-0 font-bold">During Lockdown</p>

                                <div class="flex items-center mt-6">
                                    <span class="mr-4">
                                        <img src="/src/assets/info.svg" />
                                    </span>
                                    <span>
                                        {{ stillConfined ? "You're sending" : 'You sent' }} the most emails to
                                        <span class="font-bold"
                                            >{{ metrics.forthPage.topConfinedSentName }} ({{
                                                metrics.forthPage.topConfinedSentCount
                                            }}
                                            emails/{{ metrics.forthPage.incrementTimeUnit.slice(0, -1) }})</span
                                        >.
                                    </span>
                                </div>

                                <div class="flex items-center mt-6">
                                    <span class="mr-4">
                                        <img src="/src/assets/info.svg" />
                                    </span>
                                    <span>
                                        <span class="font-bold">{{ metrics.forthPage.topConfinedReceivedName }}</span>
                                        sent you the most
                                        <span class="font-bold"
                                            >({{ metrics.forthPage.topConfinedReceivedCount }} emails/{{
                                                metrics.forthPage.incrementTimeUnit.slice(0, -1)
                                            }})</span
                                        >.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr class="insight-hr" />
                        <div class="flex">
                            <div class="my-4">
                                <p class="mx-2 my-0 font-bold">Before Lockdown</p>

                                <div class="flex items-center mt-6">
                                    <span class="mr-4">
                                        <img src="/src/assets/info.svg" />
                                    </span>
                                    <span>
                                        You sent the most emails to
                                        <span class="font-bold"
                                            >{{ metrics.forthPage.topNotConfinedSentName }} ({{
                                                metrics.forthPage.topNotConfinedSentCount
                                            }}
                                            emails/{{ metrics.forthPage.incrementTimeUnit.slice(0, -1) }})</span
                                        >.
                                    </span>
                                </div>

                                <div class="flex items-center mt-6">
                                    <span class="mr-4">
                                        <img src="/src/assets/info.svg" />
                                    </span>
                                    <span>
                                        <span class="font-bold">{{
                                            metrics.forthPage.topNotConfinedReceivedName
                                        }}</span>
                                        sent you the most
                                        <span class="font-bold"
                                            >({{ metrics.forthPage.topNotConfinedReceivedCount }} emails/{{
                                                metrics.forthPage.incrementTimeUnit.slice(0, -1)
                                            }})</span
                                        >.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr class="insight-hr sm:mb-10" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { NAMES } from '../../utils/svgs';

import Tooltip from './Tooltip.vue';

export default defineComponent({
    name: 'ReportCommunication',
    components: { Tooltip },
    data() {
        return {
            sharePageName: NAMES.fifth,
        };
    },
    computed: {
        ...mapState(['metrics']),
        ...mapGetters(['stillConfined', 'isMobile']),
    },
});
</script>
